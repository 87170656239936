<template>
  <v-footer class="nv-footer">
    <v-container class="py-6">
      <v-row>
        <v-col cols="12" sm="6" offset-sm="2" offset-md="0" offset-lg="2">
          <a @click="dataPrivacyDialog = true">Datenschutz</a>
          <a @click="imprintDialog = true" class="mx-5">Impressum</a>
        </v-col>
      </v-row>

      <v-row align-content="end">

        <v-col cols="12" sm="6" offset-sm="2" offset-md="0" offset-lg="2">
          <v-img
              min-width="150"
              max-width="250"
              src="@/assets/logo_bvds_trans.png"
          ></v-img>
        </v-col>

      </v-row>

      <v-row justify="center">
        <v-dialog
            v-model="imprintDialog"
            width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Impressum</span>
            </v-card-title>
            <v-card-text v-html="imprintContent">

            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="imprintDialog = false"
              >
                Schließen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog
            v-model="dataPrivacyDialog"
            width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Datenschutz</span>
            </v-card-title>
            <v-card-text v-html="dataPrivacyContent">
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="dataPrivacyDialog = false"
              >
                Schließen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

    </v-container>


  </v-footer>
</template>

<script>
import axios from "axios";

export default {
  name: "Footer",
  data() {
    return {
      imprintDialog: false,
      dataPrivacyDialog: false,

      imprintContent: "",
      dataPrivacyContent: "",

      logoBVDS: "http://localhost:8080/assets/logo_bvds_trans.png",
    }
  },

  created() {
    axios
        .get('https://admin.collette-hecht-stiftung.de/impressum/')
        .then(response => {
          this.data = response.data;
          this.imprintContent = response.data.Inhalt
          //console.log(this.data);
        })
        .catch(error => console.log(error));

    axios
        .get('https://admin.collette-hecht-stiftung.de/datenschutz/')
        .then(response => {
          this.data = response.data;
          this.dataPrivacyContent = response.data.Inhalt
          //console.log(this.data);
        })
        .catch(error => console.log(error));
  },
}
</script>

<style lang="sass" scoped>


</style>
