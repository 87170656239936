<template>
  <div class="snap-wrapper">

    <Start/>
    <Bichmann/>
    <Executive/>
    <Projects/>
    <Contact/>
    <Footer/>

  </div>
</template>

<script>
// @ is an alias to /src

import Footer from "@/views/sections/Footer";
//import Form from "@/views/sections/Form";
import Contact from "@/views/sections/Contact";
import Projects from "@/views/sections/Projects";
import Start from "@/views/Start";
//import axios from "axios";
import Executive from "@/views/sections/Executive";
import Bichmann from "@/views/sections/Bichmann";

export default {
  name: 'Main',
  components: {
    Executive,
    Start,
    Projects,
    Contact,
    //Form,
    Footer,
    Bichmann
  },
  data() {
    return {
      name: "Eric Livonius",
      img: "../assets/eric_livonius.png",

      data: null

    }
  },

  methods: {
  },

  mounted() {
  },
}
</script>

<style lang="sass">
@import "@/mixins/styleset.sass"

.snap-wrapper
  //scroll-snap-type: y proximity
  height: 100vh
  width: 100%


.nv-zuwendungen
  background-image: url("../assets/istockphoto-1061633278-1024x1024.jpg")
  background-position: right
  background-size: cover
  height: 60vh

  li
    font-size: large


</style>
