<template>
  <div class="nv-bichmann">

    <v-container>

      <v-row class="mb-5">
        <v-col cols="12" sm="8" md="8" lg="8" offset-sm="2" offset-md="2" offset-lg="2" class="">
          <h2 class="text-h4 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2">Wir trauern um <br> Wolfgang
            Bichmann</h2>

          <p class="mt-5">Die Collette-Hecht-Stiftung nimmt Abschied von Wolfgang Bichmann.</p>
        </v-col>
      </v-row>


      <v-row align="center" justify="center" class="mb-6">

        <v-col cols="12" sm="8" md="4" lg="5" offset-md="1" offset-lg="0" class="">

          <v-img
              max-height="350px"
              contain
              src="https://admin.collette-hecht-stiftung.de/uploads/bichmann1_8404e18f4a.jpg"
              style="filter: grayscale(100%)"
          >
          </v-img>


        </v-col>

        <v-col cols="12" sm="7" md="7" lg="6" class="">
          <v-container class="pa-0">
            <v-img
                contain
                max-height="450px"
                src="https://admin.collette-hecht-stiftung.de/uploads/bichmann_traueranzeige_131dc3021e.jpg"
            ></v-img>
          </v-container>
        </v-col>



      </v-row>

    </v-container>


  </div>

</template>

<style lang="sass">

.nv-bichmann
  background-color: #171927
  padding-top: 50px
  min-height: 60vh

  p, h2
    color: #7a6c6c !important



</style>

<script>
import axios from "axios";

export default {
  data: () => ({}),

  methods: {},

  mounted() {

  }

}
</script>

