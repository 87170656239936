<template>

  <!-- ERROR Handling -->

  <div v-if="loading"></div>

  <div v-else class="nv-start snap-box"
       :style="{ backgroundImage: 'url(' + this.api + this.data.Titelbild.formats.xlarge.url + ')'}">

    <!-- Content -->

    <v-container class="d-flex flex-column">
      <v-row class="mt-8 mt-md-4 mt-lg-0">
        <v-col cols="12" xl="8" offset-xl="2" class="white--text">

          <h1 class="nv-title text-h4 text-sm-h2 text-md-h1 text-lg-h1 text-xl-h1 text-center text-xl-left d-flex justify-center">
            {{ this.data.Titel }}</h1>
        </v-col>
      </v-row>

      <v-row class="">
        <v-col cols="12" sm="8" md="10" lg="8" offset="0" offset-sm="2" offset-md="1" offset-lg="2" class="white--text">

          <Quote>
            <h2 class="text-h5 text-sm-h3 text-md-h2 text-lg-h2 text-xl-h2 text-center">
              <p v-html="this.data.Satzungszweck"></p>
            </h2>
          </Quote>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>

import Quote from "@/components/Quote"
import axios from "axios";

export default {
  name: 'Start',
  components: {Quote},

  data: () => ({
    loading: true,
    error: false,

    data: null,

    api: 'https://admin.collette-hecht-stiftung.de',
  }),

  methods: {},

  mounted() {
    axios
        .get('https://admin.collette-hecht-stiftung.de/start/')

        .then(response => {
          this.data = response.data;
          //console.log(this.data);
        })

        .catch(error => {
          console.log(error);
          this.error = true;
        })

        .finally(() => {
              this.loading = false;
              //console.log(this.data);
            }
        );
  },
}
</script>
<style lang="sass">
@import "@/mixins/styleset.sass"

.nv-start
  background-position: right
  background-size: cover
  height: 100vh

  display: flex
  flex-direction: row

  .nv-title
    padding-top: 100px

  h1, h2
    text-shadow: 0px 1px 15px black


</style>
