<template>
  <section id="projects" class="nv-projects snap-box background">

    <!--v-container class="nv-projects-headline">
      <v-row>
        <v-col cols="6" offset="2">
          <h2 class="text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text--primary">Projekte</h2>
        </v-col>
      </v-row>
    </v-container-->

    <v-carousel
        cycle
        height="800"
        hide-delimiter-background
        show-arrows-on-hover
        hide-delimiters
    >
      <v-carousel-item
          v-for="(item, i) in projects"
          :key="i"
          :src="api + item.cover.formats.large.url"
      >

        <v-row
            class="fill-height fs-slider-overlay"
            align="center"
            justify="center"
        >
          <v-col cols="8" md="6">
            <div class="text-h5 text-sm-h4 text-md-h3 text-lg-h3 text-xl-h2 text-center white--text nv-text-highlight">
              {{ item.name }}
            </div>
          </v-col>
        </v-row>


      </v-carousel-item>
    </v-carousel>

  </section>
</template>
<script>
import axios from "axios";

export default {
  name: 'Projects',
  data: () => ({
    api: 'https://admin.collette-hecht-stiftung.de',
    projects: null,

  }),
  mounted() {
    axios
        .get('https://admin.collette-hecht-stiftung.de/projects/')

        .then(response => {
          this.projects = response.data;
          //console.log(this.projects);
        })

        .catch(error => {
          //console.log(error);
          this.error = true;
        })

        .finally(() => {
              this.loading = false;
            }
        );
  },
}
</script>
<style lang="sass" scoped>
@import "@/mixins/styleset.sass"


.nv-projects
  padding: 0 !important
  margin: 0

  .nv-text-highlight
    text-shadow: 0px 1px 15px black


</style>
