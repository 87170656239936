<template>

  <v-card
      :loading="loading"
      class="nv-image-member-container mx-auto my-12"
      max-width="350"
      min-height="380"
  >
    <template slot="progress">
      <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
      ></v-progress-linear>
    </template>

    <v-img
        height="300"
        :src="this.api + this.img"
    ></v-img>

    <v-card-title>{{ this.name }}</v-card-title>

    <v-card-text class="mb-5">
      <v-row
          align="center"
          class="mx-0"
      >

        <div class="grey--text ms-4">
          {{ this.position }}
        </div>
      </v-row>

    </v-card-text>

  </v-card>

</template>

<script>

export default {
  name: "PictureSingle",
  data: () => ({
    loading: false,
    selection: 1,
    api: "https://admin.collette-hecht-stiftung.de/"
  }),

  props: {
    img: {
      type: String,
      default: "/uploads/small_platzhalter_b9e4bf3fc3.jpg",
    },
    name: {
      type: String,
      required: true
    },
    position: null,
  },

  methods: {
    /*reserve () {
      this.loading = true

      setTimeout(() => (this.loading = false), 2000)
    },*/
  },
}
</script>

<style lang="sass" scoped>



</style>
