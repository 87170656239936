<template>
  <div id="app">
    <v-app>

      <!--MainNavigation/-->
      <router-view/>

    </v-app>
  </div>


</template>

<script>
// @ is an alias to /src
//import MainNavigation from "@/components/MainNavigation";

export default {
  name: 'Home',
  components: {
    //MainNavigation

  },
  data: function () {
    return {
      api: 'https://admin.collette-hecht-stiftung.de/',
      theme: {
        isDark: false
      }
    };
  },
  mounted() {
    document.title = 'Collette-Hecht-Stiftung'
  }

}
</script>

<style lang="sass">
//@import url('https://fonts.googleapis.com/css2?family=Newsreader:wght@400;700&family=Open+Sans&display=swap')

$grid-breakpoints: (xs: 0, sm: 340px, md: 540px, lg: 800px - 24px, xl: 1280px - 24px)

#app
  font-family: 'Open Sans', sans-serif

  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: left
  scroll-behavior: smooth
  color: black

  h1, h2, h3, h4
    font-family: 'Newsreader', serif

  h1
    font-size: 6vw
    font-weight: normal

  h2
    font-size: 4vw
    font-weight: normal

img
  width: 100%


/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar
  display: none


/* Hide scrollbar for IE, Edge and Firefox */
*
  -ms-overflow-style: none
  /* IE and Edge */
  scrollbar-width: none
  /* Firefox */


</style>


