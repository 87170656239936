<template>
  <div class="nv-executive snap-box background" id="vorstand">

    <v-container v-if="loading">
      loading
    </v-container>

    <v-container v-else>

      <v-row>
        <v-col cols="10" offset-sm="2" class="">
          <h2 class="text-h4 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text--primary">Vorstand</h2>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
            cols="12"
            sm="6"
            md="4"
            v-for="item in this.data.Vorstand"
            :key="item.id"
        >

          <!-- TODO Activate for Picture Validation -->

          <PictureSingle
              :img="item.Profilbild.url"
              :name="item.Name"
              :position="item.Beruf"
          />

        </v-col>

      </v-row>

      <v-row>
        <v-col cols="10" offset-sm="2" md="8" offset-md="2" class="">
          <h2 class="text-h4 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text--primary ">operatives Geschäft </h2>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col
            cols="12"
            sm="6"
            md="4"
            v-for="item in this.data.operativesGeschaeft"
            :key="item.id"
        >

          <!-- TODO Activate for Picture Validation -->

          <!--PictureSingle
              v-for="item in this.data"
              :key="item.Name"
              :img="item.picture.formats.small.url"
              :name="item.Name"
              :position="item.position"
          /-->

          <PictureSingle
              :img="item.Profilbild.url"
              :name="item.Name"
              :position="item.Beruf"
          />

        </v-col>

      </v-row>

    </v-container>
  </div>
</template>

<script>

import PictureSingle from "@/components/PictureSingle"
import axios from "axios";

export default {
  name: 'Executive',
  components: {
    PictureSingle
  },
  data() {
    return {
      name: "Eric Livonius",
      img: "../assets/eric_livonius.png",
      loading: true,
      data: null,
    }
  },
  props: {},
  mounted() {
    axios
        .get('https://admin.collette-hecht-stiftung.de/mitglieder/')

        .then(response => {
          this.data = response.data;
          //console.log(this.data);
        })

        .catch(error => {
          console.log(error);
          this.error = true;
        })

        .finally(() => {
              this.checkFormats();
              this.loading = false;
            }
        );
  },
  methods: {
    //TODO Picture Validation
    checkFormats() {
      /*this.data.forEach(element => {
        try {
          if (element.picture.formats) {

            console.log("All right");
            //console.log(element.picture.formats.forEach(element));
          } else {
            console.log("error not found")
          }
        } catch (e) {
          element.appendChild("avaibleFormat", "none")

          console.log(element)
          //element.picture.formats.small.url = "/uploads/small_platzhalter_b9e4bf3fc3.jpg"
        }
      })*/
    },

  },

}
</script>
<style lang="sass">
@import "@/mixins/styleset.sass"

.nv-executive
  padding-top: 50px

</style>
