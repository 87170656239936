<template>
  <div class="nv-contact">

    <v-container>

      <v-row>

        <!-- ################ KONTAKT ################  -->

        <v-col cols="12" sm="8" md="6" lg="4" offset-sm="2" offset-md="0" offset-lg="2" class="">

          <h2 class="text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text--primary">Kontakt</h2>

          <v-form v-model="valid" class="container">
            <v-container class="px-0">

              <!-- Name -->
              <v-row>
                <v-col
                    cols="12"
                    md="5"
                    class="pl-0"
                >
                  <v-text-field
                      v-model="firstname"
                      :rules="firstnameRules"
                      label="Vorname"
                      required
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    md="5"
                    class="pl-0"
                >
                  <v-text-field
                      v-model="lastname"
                      :rules="lastnameRules"
                      label="Nachname"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Mail -->
              <v-row>

                <v-col
                    cols="12"
                    md="10"
                    class="pl-0"
                >
                  <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-Mail"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>

              <!-- Formular -->
              <!--v-row>
                <v-col
                    cols="12"
                    md="6"
                    class="pl-0"
                >
                  <v-file-input
                      v-model="doc"
                      accept="image/*,.pdf"
                      label="Antragsunterlagen hochladen"
                      multiple
                  ></v-file-input>
                </v-col>

                <v-col
                    cols="12"
                    md="4"
                >
                  <v-btn
                      block
                      color="primary"
                      elevation="2"
                      large
                  >
                    <span class="d-none d-xl-block">Download Selbstauskunft</span>
                    <span class="d-block d-xl-none">Selbstauskunft</span>
                  </v-btn>
                </v-col>
              </v-row-->

              <!-- Nachricht -->
              <v-row>
                <v-col
                    cols="12"
                    md="10"
                    class="pl-0"
                >
                  <v-textarea
                      v-model="text"
                      :rules="textRules"
                      label="Anliegen"
                      clear-icon="mdi-close-circle"
                      clearable
                      required
                  ></v-textarea>
                </v-col>
              </v-row>

              <!-- Senden -->
              <v-row>
                <v-col
                    cols="12"
                    md="10"
                    class="pl-0"
                >
                  <v-btn
                      block
                      color="primary"
                      elevation="2"
                      large
                      @click="submit()"
                  >
                    Senden
                  </v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-form>

        </v-col>

        <!-- ################ /KONTAKT ################  -->

        <!-- ################ ANSCHRIFT ################  -->

        <v-col cols="12" sm="8" md="6" lg="4" offset-sm="2" offset-md="0" class="mb-8">

          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col cols="12" offset="" class="mb-10">
                <h2 class="text-h5 text-sm-h4 text-md-h3 text-lg-h2 text-xl-h2 text--primary">Anschrift</h2>
              </v-col>

              <v-col cols="12">
                Collette-Hecht-Stiftung<br>
                Große Bäckerstraße 4<br>
                20095 Hamburg<br><br>

                Tel: <a href="tel:00494097074481">+49 40 9707 4481</a><br>
                Fax: <a href="tel:00494097075499">+49 40 9707 5499</a><br><br>
                E-Mail:

                <a href="#" class="cryptedmail"
                   data-name="info"
                   data-domain="collette-hecht-stiftung"
                   data-tld="de"
                   onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;"></a>
              </v-col>
            </v-row>

          </v-container>

          <!-- ################ /ANSCHRIFT ################  -->

          <!-- ################ DOWNLOADS ################  -->

          <v-container v-if="this.downloads" class="pa-0 mt-10">
            <v-row no-gutters>
              <v-col cols="12" offset="" class="mb-10">
                <h2 class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 text-xl-h4 text--primary">Antrag für Privatpersonen</h2>
                <div class="mt-3">Bitte nutzen Sie den Selbstauskunftsbogen</div>
              </v-col>

              <v-col cols="8">

                <v-btn
                    v-for="item in this.downloads"
                    :key="item.Name"

                    block
                    color="primary"
                    elevation="2"
                    large
                    :href="api + item.Download.url"
                    target="_blank"
                >
                  <span class="d-none d-xl-block">Download {{ item.Name }}</span>
                  <span class="d-block d-xl-none">{{ item.Name }}</span>
                </v-btn>


              </v-col>
            </v-row>

            <!-- ################ /DOWNLOADS ################  -->

          </v-container>
        </v-col>

      </v-row>

    </v-container>


  </div>

</template>

<style lang="sass">

.nv-contact
  padding-top: 50px

  .cryptedmail
    &:after
      content: attr(data-name) "@" attr(data-domain) "." attr(data-tld)


</style>

<script>
import axios from "axios";

export default {
  data: () => ({
    valid: false,
    firstname: '',
    firstnameRules: [
      v => !!v || 'Nachname benötigt',
    ],
    lastname: '',
    lastnameRules: [
      v => !!v || 'Nachname benötigt',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-Mail benötigt',
      v => /.+@.+/.test(v) || 'E-Mail ist ungültig',
    ],
    text: '',
    textRules: [
      v => !!v || 'Inhalt notwendig',
    ],
    doc: null,

    api: 'https://admin.collette-hecht-stiftung.de',
    downloads: null,
  }),

  methods: {
    submit() {
      //console.log(this.doc);

      axios
          .post('https://admin.collette-hecht-stiftung.de/forms/send', {
            firstname: this.firstname,
            lastname: this.lastname,
            email: this.email,
            request: this.text,
            doc: this.doc,
          })

          .then(response => {
            this.data = response.data;
            //console.log(this.data);
          })

          .catch(error => {
            //console.log(error);
            this.error = true;
          })

          .finally(() => {
                this.loading = false;
                //console.log(this.data);
              }
          );

      this.firstname = '';
      this.lastname = '';
      this.email = '';
      this.text = '';
      this.doc = '';

      alert("Nachricht wurde gesendet");

    }

  },

  mounted() {
    axios
        .get('https://admin.collette-hecht-stiftung.de/downloads/')
        .then(response => {
          this.downloads = response.data;
          //console.log(this.downloads);
        })
        .catch(error => {
              //console.log(error)
            }
        );
  }

}
</script>

