<template class="">
  <span class="d-flex flex-column">
    <span class="nv-quote nv-left-quote d-none d-sm-flex">“</span>
    <slot class="nv-quote-text"></slot>
    <span class="nv-quote nv-right-quote d-none d-sm-flex">”</span>
  </span>
</template>

<script>
export default {
  name: "Quote"
}
</script>

<style lang="sass" scoped>

@import url('https://collette-hecht-stiftung.de/src/assets/fonts/Catamaran/Catamaran-Regular.ttf')
//@import url('http://localhost:8080/src/assets/fonts/Catamaran/Catamaran-Regular.ttf')

.nv-quote-text
  hyphens: auto
  text-align: justify

.nv-quote
  position: relative
  font-family: 'Catamaran', sans-serif
  font-size: 20rem
  display: flex
  line-height: 0
  opacity: 0.3

.nv-left-quote
  justify-content: flex-start
  transform: translate(-130px, 80px)

.nv-right-quote
  justify-content: flex-end
  transform: translate(130px, 110px)

</style>
